import React, { useState } from 'react';
import { CCardGroup, CAlert, CCardText, CButton, CRow, CCardImage, CCard } from '@coreui/react';
import UserLogin from './UserLogin';
import atgeirlogo from '../../src/assets/atgeirlogo1.png';
import "../scss/custom.scss";
import { useNavigate } from 'react-router-dom';


const Signup = () => {
  const [userEmail, setUserEmail] = useState('');
  const [userName, setUsername] = useState('')
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [activeKey, setActiveKey] = useState(1);
  const [accountApproved, setAccountApproved] = useState()
  const [googleEmailExists, setGoogleEmailExists] = useState(true);
  const [via, setVia] = useState(false)
  const navigate = useNavigate();

 
  const handleGoogleSignup = (email, name, accountExists) => {
    setUserEmail(email);
    setUsername(name);
    setGoogleEmailExists(accountExists);
    console.log("show signup triggered!", email, accountExists);

    // if email exists alert email exists, if not only then navigate
    if (accountExists === false) {
      navigate('/register-user', { state: { via: true, userEmail: email } });
    }
  };
  console.log("via set to :", via)

  const checkIsApproved = (isApproved) => {
    console.log("isApproved", isApproved)
    if (isApproved === null) {
      setAccountApproved(false)
    } else if (isApproved === 'YES') {
      setAccountApproved(true)
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Admin registration:', { email, password });
  };

  const handleSignUpButtonClick = () => {
    navigate('/register-user', { state: { via } });
  };

  return (
    <div className='signup-container'>
      <CCardGroup className='registration-card-group'>
        <CRow className='atgeir-logo-row'>
          <div className="image-wrapper">
            <CCardImage src={atgeirlogo} alt="Background Image" className="atgeir-logo" />
          </div>
        </CRow>
        <CRow>
          <CCard className='registration-card'>
            <UserLogin setActiveKey={setActiveKey}
              handleGoogleSignup={handleGoogleSignup}
              checkIsApproved={checkIsApproved}
               />
            <CRow>
              <div className='d-flex justify-content-center align-itens-center'>
                <CCardText className='info-text p-0 m-0 d-flex align-self-center'> Create a new acctount? </CCardText>
                <CButton style={{ border: 'none', backgroundColor: 'transparent', color: 'blue', fontSize: 13, marginLeft: -5 }} onClick={handleSignUpButtonClick}>SignUp</CButton>
              </div>
            </CRow>
          </CCard>
        </CRow>
        <footer className="transparent-footer">
          <div className="footer-text">
            Atgeir Solutions™© 2023 All Rights Reserved.
          </div>
        </footer>
      </CCardGroup>
    </div>
  );
};

export default Signup;
