import axios from "axios";
const config = require("./../config/config");
const API_URL = config.api_url;

class RegistrationDetailsService {
  getAllAccounts() {
    console.log("API url:", API_URL)

    try {
      var config = {
        method: 'get',
        url:  API_URL + "/v1/postgres/get_all_details",
        headers: { 
          'Content-Type': 'application/json'
        },
      };

      return axios(config)
        .then(function (response) {
          // console.log(JSON.stringify(response.data));
          return response.data
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
      return error;
    }
  }
}

export default new RegistrationDetailsService();