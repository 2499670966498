import { Table } from 'react-bootstrap';
import { createClaimsTable } from '../utils/claimUtils';
import { CAlert, CCardSubtitle } from "@coreui/react";
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { setUUID } from './../redux/actions';
import { setMatchedAccountDetails } from './../redux/actions';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AuthenticatedTemplate } from "@azure/msal-react";
import '../styles/App.css';
import registrationDetailsService from '../services/registration.details.service';

export const IdTokenData = (props) => {
    const tokenClaims = props.idTokenClaims;
    console.log("id token claims", tokenClaims)
    
    const userEmail = tokenClaims['preferred_username']
    console.log("user email in data display", userEmail)
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { state } = location;
    const { activeAccount, matchingAccount } = state || {};

    const [showAlert, setShowAlert] = useState(false);
    const [userType, setUserType] = useState('')
    const [allDetails, setAllDetails] = useState();

    useEffect(() => {
        getAccountDetails();
    },[])

    const getAccountDetails = async () => {
        try {
          const allAccountsDetails =
            await registrationDetailsService.getAllAccounts();
          setAllDetails(allAccountsDetails);
          if(allAccountsDetails !== undefined){
            handleNavigate(allAccountsDetails);
          }
          console.log("All Accounts Details in Login: ", allAccountsDetails);
        } catch (error) {
          console.error("Failed to fetch account details:", error);
        } finally {
        //   setLoading(false);
        }
      };

    const handleNavigate = (allAccountsDetails) => {
        console.log("active account in redirect page", activeAccount)
        const matchingAccount = allAccountsDetails?.find((account) => account.company_email === userEmail);

        const userAccount = matchingAccount;
        console.log("matching account details in Data Display", userAccount);
  if(matchingAccount !== undefined){
    dispatch(setMatchedAccountDetails(userAccount));
    if(userAccount !== undefined && userEmail !== undefined){
        console.log("inside if statement!!")
        if (userAccount.registration_type === "EMPLOYEE") {
            console.log("inside nested if")
            setUserType("EMPLOYEE");
            dispatch(setUUID(userAccount.uuid));
            navigate("/employee-dashboard/overview"); // Redirecting to the employee dashboard
          } else if (
            userAccount.registration_type === "ADMIN" &&
            userAccount?.is_approved === "YES"
          ) {
            setUserType("ADMIN");
            dispatch(setUUID(userAccount.uuid));
            navigate("/admin-dashboard/overview");
          } else if (userAccount.registration_type === "ROOTADMIN") {
            console.log("root admin");
            setUserType("ROOTADMIN");
            dispatch(setUUID(userAccount.uuid));
            navigate("/root-admin-dashboard/all");
          }
      }
  } else {
    console.log("user not found")
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 4000);
  }        
}

    return (
        <>
            {showAlert?
            <>
            <CAlert color='danger' className='p-2 m-2'>
              This email address is not registered with us.
            </CAlert>
            </> : 
            <div className="data-area-div m-2">
               Redirecting...
            </div>}
        </>
    );
};