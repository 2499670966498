import axios from "axios";
const config = require("./../config/config");
const API_URL = config.api_url;

class SignUpService {
  signUpUser(
    registrationType,
    ownerName,
    companyName,
    companyEmail,
    companyAddress,
    password,
    sector,
    companySize,
    noOfOffices,
    companyUUID,
    department,
    loginType
  ) {
    try {
      var data = JSON.stringify({
        REGISTRATION_TYPE: registrationType,
        OWNER_NAME: ownerName,
        COMPANY_NAME: companyName,
        COMPANY_EMAIL: companyEmail,
        COMPANY_ADDRESS: companyAddress,
        PASSWORD: password,
        SECTOR: sector,
        COMPANY_SIZE: companySize,
        NO_OF_OFFICES: noOfOffices,
        COMPANY_UUID: companyUUID,
        DEPARTMENT: department,
        LOGIN_TYPE:loginType
      });

      var requestConfig = {
        method: "post",
        url: API_URL + "/v1/postgres/register_user",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      console.log("API config:", requestConfig);

      return axios(requestConfig)
        .then(function (response) {
          // console.log(JSON.stringify(response.data));
          return response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
      return error;
    }
  }
}

export default new SignUpService();
