import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IdTokenData } from "./../components/DataDisplay";
import { loginRequest } from "./../config/auth-config";
import {
  MsalProvider,
  useMsal,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { CButton, CCardSubtitle, CRow } from "@coreui/react";
import { Container, Button } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { setUUID } from "./../redux/actions";
import { setMatchedAccountDetails } from "./../redux/actions";

const MainContent = () => {
  const [userType, setUserType] = useState("");
  const [alertText, setAlertText] = useState("");

  const location = useLocation();
  const { state } = location;
  const { allDetails } = state || {};
  console.log("allDetails value in main content", allDetails);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();

  const handleRedirect = () => {
    instance
      .loginRedirect({
        ...loginRequest,
        prompt: "create",
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    handleNavigate();
  }, []);

  const handleNavigate = () => {
    if (activeAccount) {
      const userEmail = activeAccount.idTokenClaims.preferred_username;
      const matchingAccount = allDetails?.find(
        (account) => account.company_email === userEmail
      );
      console.log("matching account details", matchingAccount);

      if (matchingAccount !== undefined) {
        const loginType = matchingAccount.login_type;
        if (loginType === "google") {
          setAlertText(
            "This email address is registered via Google. Please use Google Login."
          );
        } else if (loginType === "microsoft") {
          if (matchingAccount.registration_type === "EMPLOYEE") {
            setUserType("EMPLOYEE");
            dispatch(setUUID(matchingAccount.uuid));
            navigate("/employee-dashboard/overview"); // Redirecting to the employee dashboard
          } else if (
            matchingAccount.registration_type === "ADMIN" &&
            matchingAccount?.is_approved === "YES"
          ) {
            setUserType("ADMIN");
            dispatch(setUUID(matchingAccount.uuid));
            navigate("/admin-dashboard/overview");
          } else if (matchingAccount.registration_type === "ROOTADMIN") {
            console.log("root admin");
            setUserType("ROOTADMIN");
            dispatch(setUUID(matchingAccount.uuid));
            navigate("/root-admin-dashboard/all");
          }
        } else {
          setAlertText("This email address is not registered via Microsoft.");
        }
      }
    } else {
      //send to login page
    }
  };


  const handleNavigateback = () => {
    navigate("/");
  }

  return (
    <div className="App">
            <AuthenticatedTemplate>
                {activeAccount ? (
                    <Container>
                        <IdTokenData idTokenClaims={activeAccount.idTokenClaims} />
                    </Container>
                ) : null}
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <Button className="signInButton" onClick={handleRedirect} variant="primary">
                    Sign up
                </Button>
            </UnauthenticatedTemplate>
        </div>
  );
};

export default MainContent;
