import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  CCard,
  CCardBody,
  CForm,
  CFormInput,
  CButton,
  CSpinner,
  CAlert,
  CCardTitle,
  CRow,
  CCardText,
} from "@coreui/react";
import './../scss/applayoutcss.scss'
import { loginFields } from "../constants/registrationFormFields";
import registrationDetailsService from "../services/registration.details.service";
import { setUUID } from "./../redux/actions";
import { setMatchedAccountDetails } from "./../redux/actions";
import { GoogleLogin } from "@react-oauth/google";
import jwtDecode from "jwt-decode";
import UpdateIsActiveService from "../services/update.active.service";
import { IdTokenData } from "./../components/DataDisplay";
import { loginRequest } from "./../config/auth-config";
import {
  MsalProvider,
  useMsal,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import microsoftLogo from './../assets/microsoft-logo.jpg'

const UserLogin = ({ setActiveKey, handleGoogleSignup, checkIsApproved }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [allDetails, setAllDetails] = useState([]);
  const [userType, setUserType] = useState("");
  const [matchedAccount, setMatchedAccount] = useState();
  const [loading, setLoading] = useState(true);
  const [hasEmail, setHasEmail] = useState(false);
  const [authType, setAuthType] = useState();

  const [noEmail, setNoEmail] = useState(false);
  const [visible, setVisible] = useState(false);
  const [alertText, setAlertText] = useState("");

  const [relogin, setRelogin] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    getAccountDetails();
  }, []);

  const getAccountDetails = async () => {
    try {
      const allAccountsDetails =
        await registrationDetailsService.getAllAccounts();
      setAllDetails(allAccountsDetails);
      console.log("All Accounts Details in Login: ", allAccountsDetails);
    } catch (error) {
      console.error("Failed to fetch account details:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Check if the email and password match
    getmatchedAccount();
  };

  const getmatchedAccount = async () => {
    console.log("email is", email);
    console.log("password is", password);
    const matchedAccount = allDetails?.find(
      (account) =>
        account.company_email === email && account.password === password
    );
    console.log("Logged In Account Details:", matchedAccount);
    dispatch(setMatchedAccountDetails(matchedAccount));
    setMatchedAccount(matchedAccount);

    if (matchedAccount && matchedAccount !== undefined) {
      // is_Active set to true once user Logs in
      const id = matchedAccount.id;
      const is_active = "YES";
      const updateIsActiveResp = await UpdateIsActiveService.updateIsActive(
        id,
        is_active
      );
      console.log("updateIsActiveResp", updateIsActiveResp);

      const isApproved = matchedAccount?.is_approved;
      if (matchedAccount.registration_type === "ADMIN" && isApproved === null) {
        setVisible(true);
        setAlertText("Your request is awaiting admin approval.");
        setTimeout(() => {
          setVisible(false);
          setAlertText("");
        }, 4000);
      } else if (
        matchedAccount.registration_type === "ADMIN" &&
        isApproved === "YES"
      ) {
        setVisible(false);
        setAlertText("");
      }

      if (matchedAccount.login_type !== "google") {
        setAuthType("normal");
        const tempUserType = matchedAccount.registration_type;
        if (tempUserType === "EMPLOYEE") {
          setUserType("EMPLOYEE");
          dispatch(setUUID(matchedAccount.uuid));
          navigate("/employee-dashboard/overview"); // Redirecting to the employee dashboard
        } else if (
          tempUserType === "ADMIN" &&
          matchedAccount?.is_approved === "YES"
        ) {
          setUserType("ADMIN");
          dispatch(setUUID(matchedAccount.uuid));
          navigate("/admin-dashboard/overview");
        } else if (tempUserType === "ROOTADMIN") {
          console.log("root admin");
          setUserType("ROOTADMIN");
          dispatch(setUUID(matchedAccount.uuid));
          navigate("/root-admin-dashboard/all");
        }
      } else {
        setAuthType("google");
        // user registered via ggogle
      }
    } else {
      console.log("NO MATCHING ACCOUNT FOUND");
      // setNoEmail(true)
      setAlertText("This email address is not registered");
      setVisible(true);
      setTimeout(() => {
        // setNoEmail(false)
        setAlertText("");
        setVisible(false);
      }, 4000);
    }
    setEmail("");
    setPassword("");
  };

  const handleChange = (fieldName, value) => {
    if (fieldName === "email") {
      setEmail(value);
    } else if (fieldName === "password") {
      setPassword(value);
    }
    setVisible(false);
    setAlertText("");
    // setNoEmail(false);
  };

  const extractEmailFromToken = (accessToken) => {
    const decodedToken = jwtDecode(accessToken);
    const userEmail = decodedToken?.email || null;
    return userEmail;
  };

  const extractUserNameFromToken = (token) => {
    try {
      const decodedToken = jwtDecode(token);
      const userName = decodedToken?.name || null;
      return userName;
    } catch (error) {
      console.error("Error decoding token:", error);
      return null;
    }
  };

  const handleGoogleLoginSuccess = async (credentialResponse) => {
    console.log(credentialResponse);
    const userEmail = extractEmailFromToken(credentialResponse.credential);
    const name = extractUserNameFromToken(credentialResponse.credential);
    if (userEmail) {
      console.log("User Email from JWT:", userEmail);
      const emailExists = allDetails.some(
        (account) => account.company_email === userEmail
      );
      setHasEmail(emailExists);
      console.log("emails exists?:", emailExists);

      if (emailExists === true) {
        const checkType = allDetails.some(
          (account) => account.login_type === "google"
        );
        console.log("type is google:", checkType);

        //if checkType is true (Google) navigate on dashboard else ask to login with id and pass
        if (checkType === true) {
          handleNavigate(userEmail);
          handleGoogleSignup(userEmail, name, true);
        }
      } else {
        handleGoogleSignup(userEmail, name, false);
      }
    } else {
      console.log("No email found in JWT.");
    }
  };

  // navigation for google user
  const handleNavigate = async (userEmail) => {
    console.log("userEmail in handlenavigate", userEmail);
    const matchedAccount = allDetails.find(
      (account) => account.company_email === userEmail
    );

    const isApproved = matchedAccount?.is_approved;
    checkIsApproved(isApproved);
    if (matchedAccount.registration_type === "ADMIN" && isApproved === null) {
      setVisible(true);
      setAlertText("Your request is awaiting admin approval.");

      setTimeout(() => {
        setVisible(false);
        setAlertText("");
      }, 4000);
    } else if (
      matchedAccount.registration_type === "ADMIN" &&
      isApproved === "YES"
    ) {
      setVisible(false);
      setAlertText("");
    }

    console.log("matched account in nav func:", matchedAccount);
    if (matchedAccount && matchedAccount !== undefined) {
      dispatch(setMatchedAccountDetails(matchedAccount));
      // is_Active set to true once user Logs in
      const id = matchedAccount.id;
      const is_active = "YES";
      const updateIsActiveResp = await UpdateIsActiveService.updateIsActive(
        id,
        is_active
      );
      console.log("updateIsActiveResp", updateIsActiveResp);

      const loginType = matchedAccount.login_type;
      console.log("registered user login type", loginType);

      const tempUserType = matchedAccount.registration_type;
      console.log("user registration type: ", tempUserType);

      // Dispatch setUUID before navigating
      dispatch(setUUID(matchedAccount.uuid));

      if (tempUserType === "EMPLOYEE") {
        setUserType("EMPLOYEE");
        navigate("/employee-dashboard/overview");
      } else if (
        tempUserType === "ADMIN" &&
        matchedAccount?.is_approved === "YES"
      ) {
        setUserType("ADMIN");
        navigate("/admin-dashboard/overview");
      } else if (tempUserType === "ROOTADMIN") {
        console.log("root admin");
        setUserType("ROOTADMIN");
        navigate("/root-admin-dashboard/all");
      }
    } else {
      console.log("NO MATCHING ACCOUNT FOUND");
      setAlertText("This user is not registered");
      setVisible(true);
      setTimeout(() => {
        setVisible(false);
        setAlertText("");
      }, 5000);
    }
  };

  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();

  const handleRedirect = () => {
    instance
      .loginRedirect({
        ...loginRequest,
        prompt: "create",
      })
      .catch((error) => console.log(error));
  };

  return (
    <CCard className="custom-card">
      <CCardBody className="pb-0">
        <CRow>
          <CCardTitle
            style={{ color: "#3F3D5C" }}
            className="d-flex justify-content-start m-1 mb-3"
          >
            ESGeir™ Sign In
          </CCardTitle>
        </CRow>
        <CForm onSubmit={handleSubmit}>
          {loading ? (
            <CSpinner color="primary" />
          ) : (
            <>
              {loginFields.map((field) => (
                <CFormInput
                  key={field.name}
                  type={field.type}
                  onChange={(e) => handleChange(field.name, e.target.value)}
                  placeholder={field.placeholder}
                  className="mb-3"
                  style={{ borderRadius: "5px", borderColor: "#ced4da" }}
                />
              ))}
              <CButton
                type="submit"
                color="primary"
                className={`purple-button mt-0 mb-3`}
              >
                Login
              </CButton>
              <GoogleLogin
                className="google-button"
                onSuccess={handleGoogleLoginSuccess}
                onError={() => {
                  console.log("Login Failed");
                }}
              />
              <div className="d-flex justify-content-start">
                <AuthenticatedTemplate>
                  {activeAccount ? (
                    <div>
                      <IdTokenData
                        idTokenClaims={activeAccount.idTokenClaims}
                      />
                    </div>
                  ) : null}
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                  <CButton
                    onClick={handleRedirect}
                    className={`microsoft-button mt-3`}
                    style={{ position: "relative", overflow: "hidden" }}
                  >
                    <div className="d-flex align-items-center p-0 m-0">
                    <div style={{width:'30%'}} className="d-flex">
                    <img
                      src={microsoftLogo}
                      className="microsoft-logo"
                      alt="Microsoft Logo"
                    /></div>
                    <div className="d-flex">
                      <CCardText className="microsoft-login-text">Sign in with Microsoft</CCardText>
                    </div>
                    </div>
                  </CButton>
                </UnauthenticatedTemplate>
              </div>
            </>
          )}
        </CForm>
        <br />
        <CAlert
          className="fade-in"
          color="primary"
          dismissible
          visible={visible}
          onClose={() => {
            setVisible(false);
            setAlertText("");
          }}
        >
          {alertText || ""}
        </CAlert>
      </CCardBody>
    </CCard>
  );
};

export default UserLogin;
