import {
    cilLockLocked,
    cilMagnifyingGlass,
    cilBook,
    cilLink,
    cilFolderOpen,
    cilDollar,
    cilUser,
    cibGmail,
    cilBuilding,
    cilAddressBook,
    cilTag,
    cilChartLine,
    cilLibrary,
    cilCloudUpload,
    cilLocationPin,
  } from "@coreui/icons";


const loginFields = [
    {
      labelText: "Email address",
      labelFor: "email-address",
      id: "email-address",
      name: "email",
      type: "email",
      //autoComplete:"email",
      isRequired: true,
      placeholder: "Email address",
      icon: cilUser,
    },
    {
      labelText: "Password",
      labelFor: "password",
      id: "password",
      name: "password",
      type: "password",
      //autoComplete:"current-password",
      isRequired: true,
      placeholder: "Password",
      icon: cilLockLocked,
    },
  ];
  
  const signupFields = [
    {
      labelText: "Name",
      labelFor: "name",
      id: "name",
      name: "name",
      type: "text",
      //autoComplete:"name",
      isRequired: true,
      placeholder: "Name",
      icon: cilBuilding,
    },
    {
        labelText: "Company name",
        labelFor: "company name",
        id: "company name",
        name: "company name",
        type: "text",
        //autoComplete:"name",
        isRequired: true,
        placeholder: "Company name",
        icon: cilBuilding,
      },
    {
      labelText: "Company email",
      labelFor: "company email",
      id: "company email",
      name: "company email",
      type: "email",
      //autoComplete:"name",
      isRequired: true,
      placeholder: "Company email",
      icon: cilBuilding,
    },
    {
      labelText: "Password",
      labelFor: "password",
      id: "password",
      name: "password",
      type: "password",
      autoComplete: "new-password",
      isRequired: true,
      placeholder: "Password",
      icon: cilLockLocked,
    },
    {
      labelText: "Confirm password",
      labelFor: "confirm password",
      id: "confirm password",
      name: "confirm password",
      type: "password",
      autoComplete: "confirm-password",
      isRequired: true,
      placeholder: "Confirm password",
      icon: cilLockLocked,
    },
    {
      labelText: "Company address",
      labelFor: "company address",
      id: "company address",
      name: "company address",
      type: "text",
      //autoComplete:"name",
      isRequired: true,
      placeholder: "Company address",
      icon: cilBuilding,
    },
    {
        labelText: "Sector",
        labelFor: "sector",
        id: "sector",
        name: "sector",
        type: "text",
        //autoComplete:"owner",
        isRequired: true,
        placeholder: "Sector",
        icon: cilUser,
      },
      {
        labelText: "Size of company",
        labelFor: "size",
        id: "size",
        name: "size",
        type: "text",
        //autoComplete:"size",
        isRequired: true,
        placeholder: "Size of company",
        icon: cilLocationPin,
      },
      {
        labelText: "Number of offices",
        labelFor: "noOfOffices",
        id: "noOfOffices",
        name: "noOfOffices",
        type: "text",
        autoComplete: "noOfOffices",
        isRequired: true,
        placeholder: "Number of offices",
        icon: cibGmail,
      },   
  ];
  

export {loginFields, signupFields};