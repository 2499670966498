import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './redux/store';
import { MsalProvider, useMsal, AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import Signup from './components/Signup';
import MainContent from './constants/MainContent';
import RegisterUser from './components/RegisterUser';

const AppLayout = React.lazy(() => import('./layout/AppLayout'))
const EmployeeLayout = React.lazy(() => import('./layout/EmployeeLayout'))
const RootAdminLayout = React.lazy(() => import('./layout/RootAdminLayout'))

const App = ({ instance }) => {

    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <MsalProvider instance={instance}>
                    <Router>
                        <div style={{ display: 'flex', height: '100vh' }}>
                            <div style={{ flex: 1 }}>
                                <Routes>
                                    <Route path="/" element={<Signup/>} />
                                    <Route path="/admin-dashboard/*" element={<Suspense fallback={<div>Loading...</div>}><AppLayout /></Suspense>} />
                                    <Route path="/employee-dashboard/*" element={<Suspense fallback={<div>Loading...</div>}><EmployeeLayout/></Suspense>} />
                                    <Route path="/root-admin-dashboard/*" element={<Suspense fallback={<div>Loading...</div>}><RootAdminLayout/></Suspense>} />
                                    <Route path="/register-user" element={<RegisterUser />} />
                                    <Route path="/main-content" element={<MainContent />} />
                                </Routes>
                            </div>
                        </div>
                    </Router>
                </MsalProvider>
            </PersistGate>
        </Provider>
    );
};

export default App;
