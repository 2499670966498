const initialState = {
    uuid: '',
  };
  
  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_UUID':
        return {
          ...state,
          uuid: action.payload,
        };
      case 'SET_MATCHED_ACCOUNT':
      return {
        ...state,
        matchedAccount: action.payload,
      };
      case 'SET_EMISSIONS':
      return {
        ...state,
        totalEmission: action.payload,
      };
      case 'SET_POSITIVE_IMPACT':
      return {
        ...state,
        positiveImpact: action.payload,
      };
      case 'RESET_USER':
      return {
        ...state,
        uuid: null,
        matchedAccount: null,
        totalEmission: null,
        positiveImpact: null
      };
      default:
        return state;
    }
  };
  
  export default reducer;
  