export const setUUID = (uuid) => {
    return {
      type: 'SET_UUID',
      payload: uuid,
    };
  };
  

  export const resetUUID = () => {
    return {
      type: 'RESET_UUID',
    };
  };

  export const setTotalEmissions = (emissions) => {
    return {
      type: 'SET_EMISSIONS', 
      payload: emissions,
    };
  };

  export const setTotalPositiveImpact = (positiveImpact) => {
    return {
      type: 'SET_POSITIVE_IMPACT', 
      payload: positiveImpact,
    };
  };

  export const setMatchedAccountDetails = (account) => { 
    return {
      type: 'SET_MATCHED_ACCOUNT',
      payload: account,
    };
  };

  export const resetUser = () => ({
    type: 'RESET_USER',
  });