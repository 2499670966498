import React from 'react';
import { CCardGroup, CRow, CCardImage, CCard } from '@coreui/react';
import AdminRegistration from './AdminRegistration';
import atgeirlogo from '../../src/assets/atgeirlogo1.png';
import "../scss/custom.scss";
import { useLocation } from 'react-router-dom';


const RegisterUser = () => {
    const location = useLocation();
    const { state } = location;
    const { userEmail, via } = state || {};
    console.log("via value in register user", via)
    console.log("State in RegisterUser:", state);


    return (
        <div className='signup-container'>
            <CCardGroup className='registration-card-group'>
                <CRow className='atgeir-logo-row'>
                    <div className="image-wrapper">
                        <CCardImage src={atgeirlogo} alt="Background Image" className="atgeir-logo" />
                    </div>
                </CRow>
                <CRow>
                    <CCard className='registration-card'>
                        <AdminRegistration
                            userName={''} userEmail={userEmail} via={via} />
                    </CCard>
                </CRow>
                <footer className="transparent-footer">
                    <div className="footer-text">
                        Atgeir Solutions™© 2023 All Rights Reserved.
                    </div>
                </footer>
            </CCardGroup>
        </div>
    );
};

export default RegisterUser;
