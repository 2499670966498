import axios from "axios";
const config = require("./../config/config");
const API_URL = config.api_url;

class UpdateIsActiveService {
  updateIsActive(
    userId,
    isActive
  ) 
  {
    try {
      var data = JSON.stringify({
        "id": userId,
        "is_active": isActive,
        });

      var config = {
        method: 'put',
        url: API_URL + "/v1/postgres/update_is_approved",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      console.log("API config:", config);

      return axios(config)
        .then(function (response) {
          // console.log(JSON.stringify(response.data));
          return response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
      return error;
    }
  }
}

export default new UpdateIsActiveService();
