import React, { useState, useEffect } from 'react';
import {
  CCard,
  CCardBody,
  CForm,
  CFormInput,
  CButton,
  CCardTitle,
  CCardSubtitle,
  CAlert,
  CRow
} from '@coreui/react';
import CIcon from "@coreui/icons-react";
import { cilCheckCircle } from "@coreui/icons";
import { signupFields } from '../constants/registrationFormFields';
import "./../scss/custom.scss"
import SignUpService from './../services/signup.service'
import registrationDetailsService from '../services/registration.details.service';
import { useNavigate } from 'react-router-dom';


const AdminRegistration = ({ via, userEmail }) => {
  const [formData, setFormData] = useState({});
  const [currentStep, setCurrentStep] = useState(0);
  const [isFieldsFilled, setIsFieldsFilled] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [signupFlag, setSignupFlag] = useState(false);
  const [isFormSubmit, setIsFormSubmit] = useState(false);
  const [validEmail, setValidEmail] = useState(true);
  const [companyNameValid, setCompanyNameValid] = useState(true);
  const [companySizeValid, setCompanySizeValid] = useState(true);
  const [validOffice, setValidOffice] = useState(true);
  const [allDetails, setAllDetails] = useState();
  const [emailExists, setEmailExists] = useState(false)
  const [fieldErrors, setFieldErrors] = useState([]);

  const [showEmail, setShowEmail] = useState(true)
  const navigate = useNavigate();

  const isGoogleRegistration = via;
  console.log("isGoogleRegistration",isGoogleRegistration)

  useEffect(() => {
    getAccountDetails();
  }, [])

  const getAccountDetails = async () => {
    var allAccountsDetails = await registrationDetailsService.getAllAccounts();
    setAllDetails(allAccountsDetails)
    console.log("All Accounts Details : ", allAccountsDetails)
  }

  // validating the entire form after any changes.
  useEffect(() => {
    if(isGoogleRegistration==true){
      setIsFieldsFilled(validateFields() && !emailExists && companyNameValid && companySizeValid && validOffice);
    } else{
      setIsFieldsFilled(validateFields() && passwordsMatch && validEmail && !emailExists && companyNameValid && companySizeValid && validOffice);
    }
  }, [formData, currentStep, passwordsMatch, validEmail, emailExists, companyNameValid, companySizeValid, validOffice]);

  // validating each field after any change
  useEffect(() => {
    const password = formData['password'] || '';
    const confirmPassword = formData['confirm password'] || '';
    setPasswordsMatch(password === confirmPassword);

    const email = formData['company email'] || '';
    setValidEmail(isValidEmail(email));
    setEmailExists(isEmailExists(email));

    const companyName = formData['company name'] || formData['name'] || formData['sector'] || formData['address'] || '';
    setCompanyNameValid(isValidName(companyName));

    const companySize = formData['size'] || formData['noOfOffices'] || '';
    setCompanySizeValid(isValidCompanySize(companySize));

    const officesCount = formData['noOfOffices'] || '';
    setValidOffice(isValidOffice(officesCount));
  }, [formData]);

  // handlesubmit validations
  const isValidOffice = (officesCount) => {
    return Number.isInteger(Number(officesCount));
  };

  const isValidCompanySize = (companySize) => {
    // Company size validation logic
    return Number.isInteger(Number(companySize));
  };
  const isValidName = (name) => {
    return name.trim() !== '';
  };
  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };
  const isStrongPassword = (password) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordRegex.test(password);
  };

  const isEmailExists = (email) => {
    if (Array.isArray(allDetails)) {
      return allDetails.some((detail) => detail.company_email === email);
    }
    return false; // Email doesn't exist in the array or the array is not valid
  };

  const handleNext = () => {
    setCurrentStep((prevStep) => prevStep + 1);
    var outputRes = isEmailExists(formData['company email'])
    console.log("output", outputRes)
  };

  const handleBack = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const handleSignupSuccess = () => {
    console.log('Signup successful');
    setFormData({});
    setCurrentStep(0);
    setSignupFlag(true);
    setTimeout(() => {
      setSignupFlag(false);
      navigate('/')
    },4000)
    setIsFormSubmit(false);
    setShowEmail(false)
  };

  const validateForm = () => {
    const email = formData['company email'] || '';
    const password = formData['password'] || '';
    if(isGoogleRegistration==true){
      if (
        isEmailExists(email) || //if true
        !companyNameValid ||
        !companySizeValid ||
        !validOffice
      ) {
        return false;
      }
    } else {
      if (
        !isValidEmail(email) ||
        isEmailExists(email) || //if true
        !isStrongPassword(password) ||
        !passwordsMatch ||
        !validEmail ||
        !companyNameValid ||
        !companySizeValid ||
        !validOffice
      ) {
        return false;
      }
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Admin registration:', formData);
    setIsFormSubmit(true);

    try {
      if (formData !== undefined) {
        const companyName = correctCase(formData['company name']);
        const ownerName = correctCase(formData['name']);
        const companyEmail = !isGoogleRegistration? formData['company email'] : userEmail;
        const password = !isGoogleRegistration? formData['password'] : null;
        const companyAddress = correctCase(formData['company address']);
        const sector = formData['sector'];
        const companySize = parseInt(formData['size']);
        const noOfOffices = parseInt(formData['noOfOffices']);

        // Trimming spaces & capitalizing first letter
        function correctCase(str) {
          return str
            .replace(/\b\w/g, function (word) {
              return word.toUpperCase();
            })
            .replace(/\s+/g, ' ')
            .trim();
        }

        if (!validateForm()) {
          return;
        }

        if(isGoogleRegistration==true){
          var response = await SignUpService.signUpUser(
            'ADMIN', // registration type
            ownerName,
            companyName,
            companyEmail,
            companyAddress,
            password,
            sector,
            companySize,
            noOfOffices,
            null, // company uuid for org admin
            null,
            'google'
          );
        } else {
          var response = await SignUpService.signUpUser(
            'ADMIN', // registration type
            ownerName,
            companyName,
            companyEmail,
            companyAddress,
            password,
            sector,
            companySize,
            noOfOffices,
            null,
            null
          );
        }    

        console.log('signup response', response);
        console.log('Response properties:', Object.keys(response));

        if (response !== undefined) {
          handleSignupSuccess();
        }
      }
    } catch (error) {
      console.log('Error occurred during sign up:', error);
    }
  };

  const start = currentStep * 5;
  const end = start + 5;
  // setFinalFields
  // const currentFields = signupFields.slice(start, end);

  const currentFields = signupFields
  .slice(start, end)
  .filter((field) => {
    if (isGoogleRegistration) {
      // Exclude 'company email' and 'password' fields if registration is via Google
      return field.name !== 'company email' && field.name !== 'password'&& field.name !== 'confirm password';
    }
    return true; // Include all fields for non-Google registration
  });

  const handleChange = (fieldName, value) => {
    setSignupFlag(false)
    const updatedData = {
      ...formData,
      [fieldName]: value,
    };

    setFormData(updatedData);
    setValidationErrors(validateField(fieldName, value, updatedData));
  };

  // checking if all fields in currentStep are filled
  const validateFields = () => {
    const fieldsToValidate = currentFields.map((field) => field.name);
    return fieldsToValidate.every((field) => {
      const value = formData[field] || '';
      return value.trim() !== '';
    });
  };

  // checking if a field is a valid email, a strong password, a non-empty name, or an integer value
  const validateField = (fieldName, value, data) => {
    let errors = {};

    //Name Validation
    if (fieldName === 'name') {
      if (!value.trim()) {
        errors[fieldName] = 'Admin name is required';
      }
    }

    // Company name validation
    if (fieldName === 'company name') {
      if (!value.trim()) {
        errors[fieldName] = 'Company name is required';
      }
    }

    // Email validation
    if (fieldName === 'company email') {
      if (!value.trim()) {
        errors[fieldName] = 'Email is required';
      } else if (!isValidEmail(value)) {
        errors[fieldName] = 'Please enter a valid email';
      } else if (isEmailExists(value)) {
        errors[fieldName] = 'This email is already registered'
      }
    }

    //Company Address Validation
    if (fieldName === 'company address') {
      if (!value.trim()) {
        errors[fieldName] = 'Company address is required';
      }
    }

    //Company sector Validation
    if (fieldName === 'sector') {
      if (!value.trim()) {
        errors[fieldName] = 'Sector is required';
      }
    }

    // Password validation
    if (fieldName === 'password') {
      if (!value.trim()) {
        errors[fieldName] = 'Password is required';
      } else if (!isStrongPassword(value)) {
        errors[fieldName] = 'Password must contain at least 8 characters including uppercase, lowercase, and special characters';
      }
    }

    // Confirm password validation
    if (fieldName === 'confirm password') {
      const password = data['password'] || '';
      if (value !== password) {
        errors[fieldName] = 'Passwords do not match';
        setPasswordsMatch(false);
      } else {
        setPasswordsMatch(true);
      }
    }

    // Size of company validation
    if (fieldName === 'size') {
      if (!value.trim()) {
        errors[fieldName] = 'Size of company is required';
      } else if (!Number.isInteger(Number(value))) {
        errors[fieldName] = 'Size of company must be an integer';
      }
    }

    // Number of offices validation
    if (fieldName === 'noOfOffices') {
      if (!value.trim()) {
        errors[fieldName] = 'Number of offices is required';
      } else if (!Number.isInteger(Number(value))) {
        errors[fieldName] = 'Number of offices must be an integer';
      }
    }

    // Clear the fieldErrors array for the current field
    setFieldErrors((prevErrors) =>
      prevErrors.filter((error) => error.field !== fieldName)
    );

    // Push the error message into the fieldErrors array
    if (Object.keys(errors).length > 0) {
      setFieldErrors((prevErrors) => [...prevErrors, { field: fieldName, message: Object.values(errors)[0] }]);
    } else {
      // Remove the error message from the fieldErrors array if it exists
      setFieldErrors((prevErrors) => prevErrors.filter((error) => error.field !== fieldName));
    }
    return errors;
  };

  const isLastStep = currentStep === Math.ceil(signupFields.length / 5) - 1;


  return (
    <CCard className='custom-card'>
      <CCardBody className='outline-none'>
      <CRow>
          <CCardTitle style={{ color: '#3F3D5C' }} className='d-flex justify-content-start m-1 mb-3'>ESGeir™ Sign Up</CCardTitle>
        </CRow>
        {isLastStep ?
          <CCardSubtitle className='subtitles'>Tell us about your organisation</CCardSubtitle> :
          <CCardSubtitle className='subtitles'>Join us in driving Sustainable Impact</CCardSubtitle>
        }
        {isGoogleRegistration && showEmail && !isLastStep? 
        <CFormInput
        type={'text'}
        value={userEmail}
        placeholder={'email'}
        className="mb-3"
        disabled = {true}
        style={{ borderRadius: '5px', borderColor: '#ced4da' }}
      />
        : null}
        <CForm onSubmit={handleSubmit}>
          {currentFields.map((field, index) => (
            <div key={`${field.name}-${index}`} className="mb-3">    
            <CFormInput
              type={field.type}
              value={formData[field.name] || ''}
              onChange={(e) => handleChange(field.name, e.target.value)}
              placeholder={field.placeholder}
              className="mb-3"
              style={{ borderRadius: '5px', borderColor: '#ced4da' }}
              invalid={fieldErrors.some((error) => error.field === field.name)}
            />
              {fieldErrors.map((error) => {
                if (error.field === field.name) {
                  return (
                    <div key={error.field} className="text-danger m-1" style={{ fontSize: '0.875rem' }}>
                      {error.message}
                    </div>
                  );
                }
                return null;
              })}
            </div>
          ))}
          {!isLastStep && (
            <>
              {signupFlag ?
                <div style={{ marginBottom: 'unset' }}>
                  <CAlert
                    color="success"
                    className="d-flex align-items-center"
                    dismissible
                    style={{ marginBottom: 'unset' }}
                  >
                    <CIcon
                      icon={cilCheckCircle}
                      className="flex-shrink-0 me-2"
                      width={24}
                      height={24}
                    />
                    <div style={{ fontSize: 13 }}>Signup successful. Awaiting admin approval.</div>
                  </CAlert>
                </div> : ''}

              <CButton
                type="button"
                onClick={handleNext}
                className={`purple-button${!isFieldsFilled ? ' disabled' : ''}`}
                disabled={!isFieldsFilled}
              >
                Next
              </CButton>
            </>
          )}
          {isLastStep && (
            <>
              <CButton type="button" onClick={handleBack} className="purple-button-outline">
                Back
              </CButton>
              <CButton
                type="submit"
                color="primary"
                className={`purple-button${!isFieldsFilled ? ' disabled' : ''}`}
                disabled={!isFieldsFilled}
              >
                Register
              </CButton>
            </>
          )}
        </CForm>
                <div>
                  <CButton onClick={() => {navigate("/")}} className="purple-button-outline">
                    Back to Login
                  </CButton>
                </div>
      </CCardBody>
    </CCard>
  );
};

export default AdminRegistration;
